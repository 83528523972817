import React from "react"
import Img from "gatsby-image"
import SimpleCard from "./cards/simple"
import DoubleCard from "./cards/double"
import CardContent from "./cards/content"
import BannerStyle from "./banner.module.css"
import BackgroundImage from "gatsby-background-image"

export default ({ props }) => {
	return (
		<>
			{!!props.display && !!props.background && (
				<div className={BannerStyle.banner}>
					{props.background &&
						props.background.localFile.childImageSharp.desktop && (
							<BackgroundImage
								tag="div"
								fluid={props.background.localFile.childImageSharp.desktop}
								className={BannerStyle.background}
							/>
						)}
					{props.card.display && (
						<div className={BannerStyle.wrapper}>
							<div className={BannerStyle.cardContainer}>
								{props.card.style === "simple" && (
									<div className={BannerStyle.simpleCard}>
										<CardContent props={props.card} />
									</div>
								)}

								{props.card.style === "double" && (
									<div className={BannerStyle.doubleCard}>
										<CardContent props={props.card} />
									</div>
								)}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	)
}
