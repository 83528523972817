import React from 'react';
import {graphql} from 'gatsby';
import Layout from 'components/layout'
import { TitleBanner } from 'components/banners/titleBanner';
import PublicationCard from 'components/cards'
import Section from 'components/section'
import PageBanner from 'components/page-banner'


const DefaultTemplate = ({data, ...props}) => {
  const section = data.wordpressPage.resourcesSection
  let banner = !!data.wordpressPage.banner && data.wordpressPage.banner.display;
  return(
    <Layout
      articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
      articleClassName={`${data.wordpressPage.classes} default-tpl`}
      context = {props.pageContext}
      location = {props.location}
    >
      {!!banner &&
       <PageBanner props={data.wordpressPage.banner}/>
      }
      <div className="container px-5 mx-auto" >
        {!banner &&
         <TitleBanner title={data.wordpressPage.title} />
        }
        <div className="container mx-auto entry mb-24" style={{maxWidth:640}}>

          <div className="entry-content" dangerouslySetInnerHTML={{__html: data.wordpressPage.content}}/>
        </div>
        <footer className="entry-footer">
          {data.wordpressPage.resourcesSection &&
           <Section title={section.title}>
             <div className="mx-auto container">
               {
                 section.resources.map(
                   r=>
                     <div className="mb-5 flex justify-center" key={r.id}>
                       <PublicationCard {...r} content={r.description} />
                     </div>
                 )
               }
             </div>
           </Section>
          }
        </footer>
      </div>
    </Layout>
  );
}



export default DefaultTemplate
export const pageDefautQuery = graphql `
query DefaultPageById($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
    resourcesSection{
      title
      resources{
        id
        title
        path
        isDownloadable
        link {
          url
          target
          title
        }
        description
      }
    }
    banner: pageBanner {
      ...PageBanner
   }
  }
}
`
