import React from 'react'
import ContentStyle from './card-content.module.css'

export default ({props})=>{
 
  if(props.content_type === 'custom'){
    return (<div className="" dangerouslySetInnerHTML={{__html: props.content}} />)
  }
  else if(props.content_type === 'title'){
    return (
      <div className={ContentStyle.cardTitle}>
        {!!props.logo && !!props.logo.localFile && !!props.logo.localFile.publicURL && 
         <img src={props.logo.localFile.publicURL} width={100}/>
        }
        <h1 dangerouslySetInnerHTML={{__html: props.title}}/>
      </div>)
  }
  else if (props.content_type === 'bigtitle'){
    return (
      <div className={ContentStyle.cardBigTitle}>
        {!!props.logo && !!props.logo.localFile && !!props.logo.localFile.publicURL && 
         <img src={props.logo.localFile.publicURL} width={247}/>
        }
        <h1 className="" dangerouslySetInnerHTML={{__html: props.title}}/>
      </div>
    )
  }
}
